import React, { Suspense } from 'react';
import { Router } from "@reach/router"
import { Loading } from '../../../components/common';
const Surveys = React.lazy(() => import('../../../Views/Surveys/Surveys'));

function index(props) {
    const isSSR = typeof window === "undefined";
    return (
        <>
            {!isSSR && (
                <Suspense fallback={<Loading />}>
                    <Router>
                        <Surveys path="/dashboard/surveys/surveys-result/:id" />
                    </Router>
                </Suspense>
            )}
        </>
    )
}

export default index
